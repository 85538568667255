<template>
  <div>
    <div class="newList">
      <ul>
        <li v-for="(item, index) in newsList" :key="index">
          <div class="pull-left">
            <div class="text-center u">{{ dayjs(item.publishDate).format('DD') }}</div>
            <div class="text-center d">{{ dayjs(item.publishDate).format('YYYY-MM') }}</div>
          </div>
          <div class="pull-right">
            <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank" title="">
              <div class="t">{{ item.title }}</div>
              <div class="content">
                {{ item.artDesc | ellipsis }}
                <span>[查看详情]</span>
              </div>
            </a>
            <a
              v-else
              :href="
                `/detail/${
                  item.entTChannelUuid == '00240001' ||
                  item.entTChannelUuid == '00240002' ||
                  item.entTChannelUuid == '00240008'
                    ? '00190007'
                    : item.entTChannelUuid
                }/${item.entTArticleUuid}`
              "
              target="_blank"
              title=""
            >
              <div class="t">{{ item.title }}</div>
              <div class="content">
                {{ item.artDesc | ellipsis }}
                <span>[查看详情]</span>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </div>
    <div class="pagin">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        :pager-count="6"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { articles } from '@/api/common'
export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      newsList: [],
      pageSize: 10,
      pageNum: 1,
      total: 0
    }
  },
  filters: {
    //超过100位显示 ...
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 120) {
        return value.slice(0, 120) + '...'
      }
      return value
    }
  },
  computed: {},
  mounted() {
    var _this = this
    // console.log('id :>> ', _this.id)
    this.$nextTick(() => {
      _this.articles()
    })
  },
  methods: {
    articles() {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: _this.id,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsList = data.rows
          _this.total = data.totalRows
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      var _this = this
      _this.pageNum = val
      _this.articles()
    }
  }
}
</script>

<style>
.newList {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 20px auto 0px;
}

.newList ul {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 0;
}

.newList ul li {
  width: 100%;
  height: 164px;
  overflow: hidden;
  border-top: solid 1px #f1f1f1;
}
li {
  list-style: none;
}
.newList ul li .pull-left {
  width: 109px;
  height: 105px;
  overflow: hidden;
  background-color: #f2f2f2;
  margin-top: 34px;
  padding: 10px;
  box-sizing: border-box;
  color: #666666;
}
.pull-left {
  float: left !important;
}

.newList ul li .pull-left .u {
  height: 24px;
  overflow: hidden;
  font-size: 26px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
}

.text-center {
  text-align: center;
}
.newList ul li .pull-left .d {
  height: 24px;
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.newList ul li .pull-right {
  width: 1076px;
  height: 105px;
  overflow: hidden;
  margin-top: 34px;
  background-color: #e8e9ee;
  padding: 10px;
  box-sizing: border-box;
}
.pull-right {
  float: right !important;
}
a {
  text-decoration: none;
}
.newList ul li:hover .pull-left {
  color: #fff;
  background-color: #0162b3;
}

.newList ul li:hover .pull-right {
  color: #fff;
  background-color: #0162b3;
}
.newList ul li:hover .pull-right .t {
  color: #fff;
}
.newList ul li:hover .pull-right .content {
  color: #fff;
}

.newList ul li .pull-right .t {
  height: 33px;
  overflow: hidden;
  line-height: 33px;
  font-size: 14px;
}
.newList ul li .pull-right .content {
  height: auto;
  overflow: hidden;
  color: #6e706d;
  font-size: 13px;
  line-height: 27px;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all; */
}

.pagin .el-pagination {
  text-align: center;
}
@media (max-width: 1300px) {
  .newList {
    width: 1000px;
  }
  .newList ul li .pull-right {
    width: 874px;
  }
}
@media (max-width: 992px) {
  .newList {
    width: 96%;
  }
  .newList ul li .pull-right {
    width: calc(100% - 120px);
  }
}
@media (max-width: 768px) {
  .newList {
    margin-top: 4px;
  }
  .newList ul li .pull-left {
    width: 58px;
    padding: 2px;
    margin: 0;
    height: 82px;
  }
  .newList ul li .pull-left .u {
    font-size: 20px;
    margin-top: 16px;
  }
  .newList ul li .pull-left .d {
    font-size: 12px;
    white-space: nowrap;
  }
  .newList ul li .pull-right {
    width: calc(100% - 62px);
  }
  .newList ul li .pull-right .content {
    max-height: 36px;
    font-size: 12px;
    line-height: 18px;
  }
  .newList ul li .pull-right {
    margin: 0;
    padding: 6px;
    height: 82px;
  }
  .newList ul li .pull-right .t {
    height: 30px;
    line-height: 30px;
  }
  .newList ul li {
    height: auto;
    padding: 10px 0;
  }
}
</style>
