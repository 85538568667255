<template>
  <div class="companyProfile">
    <div class="contactBody" v-html="content">
    </div>
  </div>
</template>
<script>
import { getArtInfoByChannel } from '@/api/common'
export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: ''
    }
  },
  mounted() {
    var _this = this
    this.$nextTick(() => {
      _this.getArtInfoByChannel()
    })
  },
  methods: {
    // 获取详情
    getArtInfoByChannel() {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: _this.id
      }
      getArtInfoByChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.content = data.content
        }
      })
    }
  }
}
</script>

<style lang="scss">
.companyProfile {
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 1px 4px #333333;
  padding: 30px 50px;
  background-color: #ffffff;
  line-height: 24px;
  box-sizing: border-box;
}
.companyProfile .contactBody {
  min-height: 500px;
  line-height: 30px;
}
.companyProfile .contactBody p {
  padding: 0px;
  line-height: 3em;
  text-indent: 0em;
  font-family: '宋体';
  font-size: 18px;
  line-height: 28px;
  margin: 5px 0px;
}
.companyProfile .contactBody video {
  display: inline-block;
  vertical-align: baseline;
}

.companyProfile .contactBody .groupVideo {
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  line-height: 1;
  word-break: initial;
  width: 800px;
  height: 450px;
}
.companyProfile .navMainLeft {
  width: 900px;
  float: left;
}
.companyProfile .navMainLeft a {
  height: 42px;
  line-height: 42px;
  display: inline-block;
  margin: 0px 5px;
  padding: 0px 10px;
  float: left;
  color: #333;
  text-decoration: none;
  font-family: '微软雅黑', 'Microsoft YaHei', sans-serif;
  font-size: 16px;
}
.companyProfile .character p {
  text-indent: 2em;
  text-align: justify;
}
@media (max-width: 1300px) {
  .companyProfile {
    width: 1000px;
  }
  .contactBody video {
    max-width: 100% !important;
  }
  .contactBody img {
    max-width: 100% !important;
  }
}
@media (max-width: 992px) {
  .companyProfile {
    width: 96%;
  }
}
@media (max-width: 768px) {
}

</style>
