<template>
  <div class="radioReport">
    <div class="ziHead">
      <img :src="indexImg" style="width: 100%; height: 100%" />
    </div>
    <div class="ziNav">
      <div class="navMain">
        <div class="navMainLeft">
          <span
            class="navMainInner"
            :class="{ active: active == item.entTChannelUuid }"
            v-for="(item, index) in navSubList"
            :key="index"
            @click="handleTab(item, index)"
          >
            {{ item.name }}
          </span>
        </div>

        <div class="navMainRight">
          <span>
            <router-link to="/"><i class="iconfont">&#xe689;</i> <span> &nbsp;> </span> </router-link>
          </span>
          <a href="/pressCenter/radioReport/00240001" class="textTitle">{{ mathedTitle }} </a>
          <span> &nbsp;> {{ pathtitle }} </span>
        </div>
      </div>
    </div>
    <div class="ziBase">
      <div class="ziContact">
        <div class="rowContact">
          <list-detail v-if="isDeail" ref="listDetail" :id="listId"></list-detail>
          <new-list v-if="isList" ref="newList" :id="listId"></new-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getChildChannel } from '@/api/common'
import listDetail from '@/components/listDetail/index.vue'
import newList from '@/components/newList/index.vue'
export default {
  components: {
    listDetail,
    newList
  },
  data() {
    return {
      listId: '',
      isDeail: false,
      isList: false,
      active: 0 ,
      indexImg: '',
      pathtitle: '',
      mathedTitle: '企业视讯',
      navSubList: []
    }
  },
  computed: {},
  mounted() {
    var _this = this

    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    _this.getSubGrop()
  },
  methods: {
    // 获取二级栏目
    getSubGrop() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        parentUuid: '00190007'
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.navSubList = data
          data.length &&
            data.forEach(line => {
              if (line.entTChannelUuid == _this.listId) {
                _this.pathtitle = line.name
                _this.indexImg = line.fileName
                if (line.catalog == 1) {
                  this.isDeail = true
                } else {
                  this.isList = true
                }
              }
            })
        }
      })
    },
    handleTab(item) {
      // console.log('item :>> ', item);
      this.isDeail = false
      this.isList = false
      this.$router.push(`/pressCenter/radioReport/${item.entTChannelUuid}`)
      this.pathtitle = item.name
      this.indexImg = item.fileName
      this.active = item.entTChannelUuid
      this.listId = item.entTChannelUuid
      this.$nextTick(() => {
        if (item.catalog == 1) {
          this.isDeail = true
        } else {
          this.isList = true
        }
      })
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}

.radioReport {
  width: 100%;
  height: auto;
  overflow: hidden;
  min-height: 902px;
}
.radioReport .ziHead {
  width: 100%;
  height: 417px;
  overflow: hidden;
  // background: url(../../../../assets/image/zihead.jpg) center no-repeat;
  background-size: 100% 100%;
}

.radioReport .ziNav {
  height: 42px;
  border-bottom: 1px #cacaca solid;
  margin-top: 15px;
  width: 100% !important;
}
.radioReport .navMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.radioReport .navMainLeft {
  width: 900px;
  float: left;
}
.radioReport .navMainLeft a {
  height: 42px;
  line-height: 42px;
  display: inline-block;
  margin: 0px 5px;
  padding: 0px 10px;
  float: left;
}
.radioReport .navMainLeft a:hover {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.radioReport .navMainLeft a.active {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.radioReport .navMainRight {
  width: 300px;
  float: right;
  overflow: hidden;
  font-size: 13px;
  color: #a2a2a2;
  margin-top: 15px;
}
.radioReport .navMainRight span {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
}

.radioReport .navMainRight .textTitle {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
}
.radioReport .ziBase {
  width: auto;
  height: auto;
  overflow: hidden;
}
.radioReport .ziContact {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.radioReport .rowContact {
  margin-top: 0px;
  padding-bottom: 20px;
  // background-color: rgb(206, 30, 30);
}
.navMainInner {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
    font-size: 17px;
}
.navMainInner.active {
  border-bottom: 2px solid #0263b2;
}
@media (max-width: 1300px) {
  .businessUnit .navMain,
  .companyProfile {
    width: 1000px;
  }
  .businessUnit .navMainLeft,
  .businessUnit .navMainRight {
    width: auto;
  }
}
@media (max-width: 992px) {
  .businessUnit .navMain,
  .companyProfile {
    width: 96%;
  }
  .companyProfile {
    padding: 20px 20px;
  }
  .companyProfile .contactBody video {
    max-width: 100% !important;
  }
  .companyProfile img {
    max-width: 100% !important;
  }
  .businessUnit .ziHead {
    height: 362px;
  }
}
@media (max-width: 768px) {
  .businessUnit .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .businessUnit .navMainRight {
    display: none;
  }
  .businessUnit .ziHead {
    height: 200px;
  }
  .businessUnit .ziContact {
    padding: 0;
    margin: 0;
  }
  .companyProfile {
    padding: 20px 10px;
  }
  .contactBody h1 {
    font-size: 22px !important;
  }
}
</style>
